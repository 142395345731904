<template>
  <div id="app-footer">
    <div class="inner-container">
      <div class="text-center font-weight-bold mb-4">
        <a
          href="https://www.willed.com.au/platform-terms-and-conditions/"
          target="_blank"
          >Terms & Conditions</a
        >
        /
        <a
          href="https://www.willed.com.au/privacy-policy/"
          target="_blank"
          >Privacy Policy</a
        >
      </div>
      <div class="text-center font-weight-bold">
        Willed Pty Ltd (ABN 20 638 925 032).<br />100% Australian owned and
        operated.
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppFooter",
  data: () => ({}),
}
</script>

<style lang="scss"></style>
