<template>
  <div id="app-layout">
    <div class="bg-sand">
      <div class="inner-container">
        <app-header-checkout />
      </div>
    </div>
    <div class="bg-white py-4">
      <div class="inner-container-narrow pt-4 pb-8">
        <checkout-page-title :title="pageTitle" />

        <div
          v-for="item in selectionsPrimary"
          :key="item.key"
          class="mb-4"
        >
          <product-primary v-bind="item.attributes">
            <div
              v-if="context === 'partnerAccount'"
              class="text-center"
            >
              Your partner has purchased this product for you. Complete the
              checkout to add to your account.
            </div>
          </product-primary>
        </div>

        <div
          v-if="context === 'purchase'"
          class="text-right"
        >
          <v-btn
            text
            small
            color="info"
            @click="openCode = true"
            >Apply Code</v-btn
          >
        </div>

        <template v-if="selectionsAddons.length || selectionsPartners.length">
          <div class="text-lg mb-2 mt-6 font-weight-bold">Add ons</div>
          <p class="mb-4">
            Click the options below that you would like to buy now.
          </p>

          <div v-if="selectionsAddons.length">
            <div class="mb-4 mt-4 font-weight-bold">For you</div>
            <product-select
              v-for="item in selectionsAddons"
              :key="item.key"
              v-bind="item.attributes"
              v-on="item.events"
              class="mb-4"
            />
          </div>
          <div v-if="selectionsPartners.length">
            <div class="mb-4 mt-4 font-weight-bold">For your partner</div>
            <product-select
              v-for="item in selectionsPartners"
              :key="item.key"
              v-bind="item.attributes"
              v-on="item.events"
              class="mb-4"
            />
            <p class="text-sm text-center">
              <strong>Note:</strong> After purchasing a Will or POA for your
              partner, we will send you a link to give to your partner so they
              can create their Willed account without needing to pay.
            </p>
          </div>
        </template>

        <div class="text-center pb-4 pt-6">
          <v-btn
            v-bind="largeButton"
            @click="openCheckout = true"
            ><template v-if="checkoutTotal === 0">Continue</template
            ><template v-else>Buy Now</template> (${{ checkoutTotal }})</v-btn
          >
          <v-img
            src="@/v2/assets/images/stripe-badge.png"
            width="340"
            class="d-block mx-auto mt-6"
          ></v-img>
        </div>
      </div>
    </div>
    <app-footer />

    <drawer
      :open.sync="openCode"
      title="Apply Discount"
    >
      <div class="pa-4">
        <purchase-discount-code
          v-if="openCode"
          @next="openCode = false"
          @close="openCode = false"
        />
      </div>
    </drawer>

    <drawer
      :open.sync="openCheckout"
      title="Payment"
    >
      <payment-form
        v-if="openCheckout"
        :items="checkoutItems"
        :total="checkoutTotal"
        :partner-token="
          context === 'partnerAccount' ? partner_purchase.id : null
        "
        :promo-code="
          discountDetails?.type === 'promocode' ? discountDetails.code : null
        "
        :prepaid-code="
          discountDetails?.type === 'prepaid' ? discountDetails.code : null
        "
      />
    </drawer>
  </div>
</template>

<script>
import { mapGetters } from "vuex"

import AppFooter from "@/v2/components/site/AppFooter"
import AppHeaderCheckout from "@/v2/components/site/AppHeaderCheckout"
import Drawer from "@/v2/components/wrapper/Drawer"
import PurchaseDiscountCode from "@/v2/layouts/purchase/DiscountCode.vue"
import CheckoutPageTitle from "@/v2/layouts/purchase/PageTitle.vue"
import PaymentForm from "@/v2/layouts/purchase/PaymentForm"
import ProductPrimary from "@/v2/layouts/purchase/ProductPrimary.vue"
import ProductSelect from "@/v2/layouts/purchase/ProductSelect.vue"

export default {
  name: "CheckoutPage",
  components: {
    PurchaseDiscountCode,
    ProductPrimary,
    Drawer,
    PaymentForm,
    ProductSelect,
    CheckoutPageTitle,
    AppHeaderCheckout,
    AppFooter,
  },
  data: () => ({
    openCheckout: false,
    openCode: false,
    context: "purchase",
    productOptions: {
      primary: ["will"],
      addons: [],
      partners: [],
    },
    selectedProducts: {
      will: false,
      poa: false,
      partnerWill: false,
      partnerPoa: false,
      subscription: false,
    },
  }),
  created() {
    window.scrollTo(0, 0)
  },
  mounted() {
    if (this.user_access.hasPurchasedWill) {
      if (this.user_access.isGracePeriod) {
        this.context = "renew"
        this.title = "Renew your account"
        this.productOptions.primary = ["subscription"]
        this.selectedProducts.subscription = true
        return
      } else {
        this.context = "reactivateWill"
        this.title = "Reactivate your account"
        this.productOptions.primary = ["subscription"]
        this.selectedProducts.subscription = true
        return
      }
    }

    let prePurchased = this.prePurchasedProducts()
    if (prePurchased.length) {
      this.context = "partnerAccount"
      this.productOptions.primary = prePurchased
    }

    // Addons
    if (!this.productOptions.primary.includes("will")) {
      this.productOptions.addons.push("will")
    }
    if (!this.productOptions.primary.includes("poa") && this.isPoaAvailable) {
      this.productOptions.addons.push("poa")
    }

    // Partner
    if (this.context === "purchase" && this.isPartnerAvailable) {
      this.productOptions.partners = ["partnerWill"]
      if (this.isPoaAvailable) {
        this.productOptions.partners.push("partnerPoa")
      }
    }

    this.productOptions.primary.forEach((k) => {
      this.selectedProducts[k] = true
    })
  },
  methods: {
    prePurchasedProducts() {
      let products = []
      let { users = [], claimed = true, access = {} } = this.partner_purchase
      let uid = this.auth_user.uid

      if (claimed) return products

      if (users.includes(uid)) return products

      if (access.will) products.push("will")
      if (access.poa) products.push("poa")

      return products
    },
  },
  computed: {
    ...mapGetters("app", [
      "partner_purchase",
      "poa_states",
      "price_structure",
      "discount_code",
      "referrer_discount",
    ]),
    ...mapGetters("account", [
      "user_address",
      "auth_user",
      "user_access",
      "user_partner",
    ]),
    isPoaAvailable() {
      return this.poa_states.includes(this.user_address.value.state)
    },
    isPartnerAvailable() {
      return this.user_partner?.optionItem?.partner
    },
    discountDetails() {
      let discount = this.referrer_discount

      discount = this.discount_code || discount

      return discount
    },
    productDetails() {
      let prices = this.price_structure

      let willDiscountPrice =
        this.discountDetails?.will || this.discountDetails?.will === 0
          ? this.discountDetails.will
          : prices.will.default

      let details = {
        poa: {
          name: "Powers of Attorney",
          productType: "poa",
          features: [
            {
              label: "Your Powers of Attorney",
            },
            {
              label: "Unlimited changes for 1 year",
            },
            {
              label: "Download instantly",
            },
          ],
          bonus: [
            {
              label: "Store information in your Digital Vault",
            },
            {
              label: "Make your Funeral Wishes known",
            },
          ],
          prices: {
            full: prices.poa.default,
            primary: this.context === "partnerAccount" ? 0 : prices.poa.default,
            addon: prices.poa.default - prices.addOnDiscount,
          },
        },
        will: {
          name: "Legal Will",
          productType: "will",
          features: [
            {
              label: "Your legally valid Will",
            },
            {
              label: "Unlimited changes for 1 year",
            },
            {
              label: "Download instantly",
            },
            {
              label: "Reviewed by our legal team on request",
            },
          ],
          bonus: [
            {
              label: "Store information in your Digital Vault",
            },
            {
              label: "Make your Funeral Wishes known",
            },
          ],
          prices: {
            primary: this.context === "partnerAccount" ? 0 : willDiscountPrice,
            addon: prices.will.default - prices.addOnDiscount,
          },
        },
        partnerWill: {
          name: "Partners Will",
          productType: "will",
          features: [],
          bonus: [],
          prices: {
            partner: prices.will.partner,
          },
        },
        partnerPoa: {
          name: "Partners Powers of Attorney",
          productType: "poa",
          features: [],
          bonus: [],
          prices: {
            partner: prices.poa.partner,
          },
        },
        subscription: {
          name: "Yearly Account Renewal",
          productType: "subscription",
          features: [
            {
              label: "Additional 12 months of access",
            },
            {
              label: "Update your Will",
            },
            {
              label: "Download instantly",
            },
            {
              label: "Update your Digital Vault",
            },
            {
              label: "Update your wishes",
            },
          ],
          bonus: [],
          prices: {
            primary: prices.subscription[prices.defaultSubscription],
          },
        },
      }

      if (this.context === "reactivateWill") {
        details.subscription.name = "Account reactivation"
        details.subscription.prices.primary = prices.will.reactivate
      }

      return details
    },
    selectionsPrimary() {
      let primary = this.productOptions.primary.map((k) => {
        let subtitle = null
        if (this.context === "reactivateWill") {
          let d = this.user_access.value.expiryDate
          let month = `0${d.month}`.slice(-2)
          let day = `0${d.day}`.slice(-2)
          let exp = `${d.year}-${month}-${day}`
          subtitle = `Account lapsed on ${exp}`
        }

        return {
          key: k,
          attributes: {
            title: this.productDetails[k].name,
            price: this.productDetails[k].prices.primary,
            priceFrom: this.price_structure[k].default,
            selected: this.selectedProducts[k],
            subtitle: subtitle,
            features:
              this.context === "partnerAccount"
                ? []
                : this.productDetails[k].features,
            bonusFeatures:
              this.context === "partnerAccount"
                ? []
                : this.productDetails[k].bonus,
          },
          events: {
            toggle: () => {
              this.selectedProducts[k] = !this.selectedProducts[k]
            },
          },
        }
      })

      return primary
    },
    selectionsAddons() {
      let addons = this.productOptions.addons.map((k) => {
        return {
          key: k,
          attributes: {
            title: this.productDetails[k].name,
            price: this.productDetails[k].prices.addon,
            priceFrom: this.price_structure[k].default,
            subtitle: `Buy now to save $${
              this.price_structure[k].default -
              this.productDetails[k].prices.addon
            }`,
            selected: this.selectedProducts[k],
          },
          events: {
            toggle: () => {
              this.selectedProducts[k] = !this.selectedProducts[k]
            },
          },
        }
      })

      return addons
    },
    selectionsPartners() {
      let addons = this.productOptions.partners.map((k) => {
        let details = this.productDetails[k]
        return {
          key: k,
          attributes: {
            title: details.name,
            price: this.price_structure[details.productType].partner,
            priceFrom: this.price_structure[details.productType].default,
            selected: this.selectedProducts[k],
            subtitle: `Buy now to save $${
              this.price_structure[details.productType].default -
              this.price_structure[details.productType].partner
            }`,
          },
          events: {
            toggle: () => {
              this.selectedProducts[k] = !this.selectedProducts[k]
            },
          },
        }
      })

      return addons
    },
    checkoutItems() {
      let items = []

      this.selectionsPrimary.forEach((o) => {
        if (o.attributes.selected) {
          items.push({
            name: o.attributes.title,
            key: o.key,
            price: o.attributes.price,
          })
        }
      })

      this.selectionsAddons.forEach((o) => {
        if (o.attributes.selected) {
          items.push({
            name: o.attributes.title,
            key: o.key,
            price: o.attributes.price,
          })
        }
      })

      this.selectionsPartners.forEach((o) => {
        if (o.attributes.selected) {
          items.push({
            name: o.attributes.title,
            key: o.key,
            price: o.attributes.price,
          })
        }
      })

      return items
    },
    checkoutTotal() {
      return this.checkoutItems.reduce((a, b) => {
        return a + b.price
      }, 0)
    },
    pageTitle() {
      if (this.context === "renew") {
        return "Renew your account"
      }

      if (this.context === "reactivateWill") {
        return "Renew your account"
      }

      if (this.context === "partnerAccount") {
        return "Checkout to claim your partner account"
      }

      return "Congratulations on completing your will!"
    },
  },
}
</script>

<style lang="scss"></style>
