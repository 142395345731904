<template>
  <div class="d-flex py-4 align-center">
    <h2>Checkout</h2>
    <v-spacer />
    <v-btn text @click="$router.push('/')">Exit</v-btn>
  </div>
</template>

<script>
export default {
  name: 'AppHeaderCheckout',
  data: () => ({
  }),
}
</script>

<style lang="scss">
#app-logo {
  display: block;
  width: 140px;
  height: auto;
  @media only screen and (min-width: 600px) {
    width: 180px;
  }
}
#app-phone {
  display: none;
  @media only screen and (min-width: 600px) {
    display: flex;
  }
}
</style>
