<template>
  <div
    class="checkout-option overflow-hidden"
    :class="{ selected: selected }"
  >
    <v-list-item @click="$emit('toggle')">
      <v-list-item-avatar
        v-if="selected"
        left
        color="success"
        size="24"
      >
        <v-icon
          size="16"
          color="white"
          >mdi-check-bold</v-icon
        >
      </v-list-item-avatar>
      <v-list-item-avatar
        v-else
        left
        color="white"
        size="24"
        style="border: 2px solid #d6dce7 !important"
      >
      </v-list-item-avatar>
      <v-list-item-content>
        <h3>{{ title }}</h3>
        <div class="text-sm opacity-70">{{ subtitle }}</div>
      </v-list-item-content>
      <v-list-item-action>
        <div
          v-if="priceFrom && price < priceFrom"
          class="original-price"
        >
          ${{ priceFrom }}
        </div>
        <h3>${{ price }}</h3>
      </v-list-item-action>
    </v-list-item>
    <template v-if="info">
      <div class="divider" />
      <v-expand-transition>
        <div v-show="localExpanded">
          <div class="text-sm pa-4">
            {{ info }}
          </div>
        </div>
      </v-expand-transition>
      <v-btn
        tile
        block
        @click="localExpanded = !localExpanded"
        text
        class="bg-grey-20"
        ><span v-if="localExpanded">Close</span
        ><span v-else>More info</span></v-btn
      >
    </template>
  </div>
</template>

<script>
export default {
  name: "ProductSelect",
  props: {
    selected: {
      default: false,
    },
    expanded: {
      default: false,
    },
    price: {
      default: null,
    },
    priceFrom: {
      default: null,
    },
    title: {
      default: null,
    },
    subtitle: {
      default: null,
    },
    info: {
      default: null,
    },
  },
  data: () => ({}),
  computed: {
    localExpanded: {
      get() {
        return this.expanded
      },
      set(val) {
        this.$emit("update:expanded", val)
      },
    },
  },
}
</script>

<style lang="scss">
.checkout-option {
  border-radius: 4px;
  border: 1px solid var(--grey);
  &.selected {
    border-color: #888;
  }
  .original-price {
    text-decoration: line-through;
    font-size: 14px;
    margin-top: -2px;
    opacity: 0.7;
  }
}
</style>
